.coupon-home{
    .coupon-home__list{
        border: 1px solid #f2f2f2;
        margin: 25px 0px;
        .owl-nav{
            position: absolute;
            top: 25%;
            left: -2%;
            width: 104%;
            z-index: 10;
            display: flex!important;
            justify-content: space-between;
            align-items: center;
            button span{
                color: #e56a7e;
                font-size: 60px;
            }
        }
        .owl-stage-outer{
            z-index: 20;
        }
        @include MediaQuery($xs){
            border: none;
            margin: 0px;
            .owl-nav{
                position: absolute;
                top: -16%;
                left: -2%;
                width: 104%;
                z-index: 10;
                display: flex!important;
                justify-content: space-between;
                align-items: center;
                button span{
                    color: #e56a7e;
                    font-size: 60px;
                }
            }
            .owl-stage-outer{
                z-index: 20;
            }
        }
    }
    .coupon-home__item{
        padding-top: 15px;
        padding-bottom: 15px;
        border-right: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        transition: 0.2s;
        &:nth-child(4n+4){
            border-right: none;
        }
        @include MediaQuery($xs){
            border-right: none; 
            border-bottom: none; 
            padding: 5px;
        }
        &-img{
            margin-bottom: 8px;
            img{
                width: 130px;
                height: auto;
                margin: 0 auto;
                @include MediaQuery($xs){
                    width: 60%;
                }
            }
        }
        &-title{
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #0056b3;
            padding-bottom: 5px;
            line-height: 25px;
            height: 50px;
            overflow: hidden;
            @include MediaQuery($lg){
                max-height: none;
            }
            @include MediaQuery($xs){
                font-size: 11px;
                padding-bottom: 5px;
                line-height: 18px;
                max-height: 100%;
                overflow: hidden;
            }
        }
        &:hover{
            box-shadow: 0 0px 5px 3px rgba(0,0,0,0.2);
            transition: 0.2s;
            .coupon-home__item-title{
                color: #24a4a7;
            }
        }
    }
}