.modal-event{
    position: fixed;
    background-color:#e56a7e;
    color: #fff;
    bottom: 60px;
    right: 15px;
    border-radius: 25px;
    z-index: 500;
    .modal-event__contain{
        position: relative;
        .modal-event__close{
            .gg-close{
                cursor: pointer;
                margin-left: auto;
                margin-right: 10px;
                margin-top: 10px;
                transform: scale(1.2);
                transition: all 0.25s ease-in-out;
                &:hover{
                    transform: scale(1.4);
                }
            }
        }
        .modal-event__img{
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.25s ease-in-out;
        }
        .modal-event__content{
            padding: 25px 15px;
            width: 225px;
            height: 150px;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .event-modal__item{
                margin-bottom: 5px;
                width: 100%;
                a{  
                    font-size: 13px;
                    color: #e9acb6;
                }
            }
            /* width */
            &::-webkit-scrollbar {
              width: 5px;
              border-radius: 30px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              background: transparent;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: transparent;
              border-radius: 30px;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: transparent;
              border-radius: 30px;
            }
        }
    }
}

.rotate-center {
	-webkit-animation: rotate-center 0.6s ease-in-out 1s infinite both;
	        animation: rotate-center 0.6s ease-in-out 1s infinite both;
}

@-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
              transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(-10deg) scale(1.05);
              transform: translate(-50%, -50%) rotate(-10deg) scale(1.05);
    }
  }
  @keyframes rotate-center {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
                transform: translate(-50%, -50%) rotate(0deg) scale(1);
      }
      100% {
        -webkit-transform: translate(-50%, -50%) rotate(-10deg) scale(1.05);
                transform: translate(-50%, -50%) rotate(-10deg) scale(1.05);
      }
  }