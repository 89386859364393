$color: #fa587d;
@media (min-width:1310px) {
    .container { max-width: 1280px; }
}
@media (min-width:1400px) {
.slider-tienich .item img{height: 130px;}
}
@media screen and (min-width:1200px) and (max-width:1400px) {
    .container { 
        max-width: 80%; 
    }
}
@media screen and (min-width:992px) and (max-width:1200px) {
    .container { 
        max-width: 100%;
    }
    .slider-home{
        .row{
            .col-12.col-lg-9{
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}
@media screen and (min-width:992px) {
    #menu-mobile, .menus-mobile { display: none; }
    .new-post-blogs.img { padding-top: 100px; }
}
@media screen and (min-width:767px) {
    .mobile-slider { display: none !important; }
}
@media screen and (max-width:991px) {
    div#menu-destop { display: none; }
    .slider-home .list-slide .owl-nav button span { font-size: 60px; color: #fff; padding: 0 30px; }
    .list-video { .all-video { display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; }
    }
    .list-post .items .items-cont { overflow: hidden; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; }
    .menu-footer-new #footer-menu { display: grid; grid-template-columns: 1fr 1fr 1fr; }
    .container { max-width: 100%; }
    #menu-destop, nav#top-nav, .post-item .post-contents .post-detail .post-excerpt, .meta .meta-social { display: none; }
    .img-logo { img { height: 47px; width: auto; }
    }
    .section { padding: 0px; }
    .main-nav { padding: 5px 0; }
    #menu-mobile { .menu-bar { i { font-size: 30px; color: $color; }
        }
    }
    .menus-mobile { #list-menu-mobile { .current-menu-item { a { color: $color; font-weight: bold; }
            }
            .menu-item-has-children { position: relative;
                >i { color: #c52a43; font-size: 25px; width: 50px; position: absolute; right: 0; top: 0; z-index: 1; padding: 15px 10px; line-height: 22px; cursor: pointer; text-align: center; -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg); transition: -webkit-transform 0.3s; transition: transform 0.3s; transition: transform 0.3s, -webkit-transform 0.3s; }
                >ul { padding-left: 20px; display: none;
                    li { a { font-size: 18px; }
                    }
                }
            }
        }
        .secondary-mobile-menu { ul { justify-content: center;
                li { padding: 0 15px; }
            }
            .search-bar { #search { display: grid; grid-template-columns: 7fr 3fr; grid-gap: 10px; padding: 10px 8px;
                    input { background-color: rgba(0, 0, 0, 0.24); border-color: rgba(255, 255, 255, 0.1); color: #ffffff; font-size: 16px; width: 100%; padding: 10px; border-radius: 2px;
                        &::placeholder { color: #ffffff; }
                    }
                    button { border-radius: 2px; width: 100%; background-color: #0c0000; color: #FFFFFF; }
                }
            }
        }
    }
    .overlay { height: auto;overflow: auto; ;transform: translateY(52px); width: 0; position: fixed; z-index: 999; top: 0; left: 0; background-color: #fff; overflow-x: hidden; transition: 0.5s; border-radius: 20px;
        .closebtn { text-align: center; font-size: 40px; }
        a { padding: 8px; text-decoration: none; font-size: 20px; color: #c52a43;; display: block; transition: 0.3s;
            &:hover { color: #e56a7e; }
        }
    }
    .overlay-content { position: relative; top: 25%; width: 100%; text-align: center; margin-top: 30px; }
    .itempost-small { grid-template-columns: 3fr 7fr; grid-gap: 10px; margin-bottom: 10px; }
    .itempost-small img, .post-item .post-thumbs img { height: 100px; }
    .post-item.block-item, .post-item { grid-gap: 10px; margin-bottom: 10px; display: grid !important; grid-template-columns: 35fr 65fr; }
    .post-item .post-contents .post-detail h2 a { font-size: 15px; line-height: 20px; }
    .sidebar-left { margin-top: 30px; }
    .post-detail.mt-2, .post-contents.mt-3 { margin-top: 0 !important; }
    .cate-title { font-size: 31px; line-height: 40px; padding: 0; }
    .meta.mt-3 { margin-top: 0 !important; }
    .slider-tienich { margin-top: 0;
        .owl-nav { top: 10%; }
        .owl-dots { display: none; }
        .item img { height: 100px; width: auto; margin: 0 auto; }
    }
    .slider-home { .owl-dots { display: none; }
    }
    .intro-sections .cate-headding h1, .intro-sections .cate-headding h2 { font-size: 120px; }
    .cate-headding { height: 100%; display: flex; align-items: center; justify-content: center; }
    .basic-section { .new-dessign .list-post .items { display: grid; }
        .top { display: none; }
    }
}
@media screen and (max-width:768px) {
    .list-post.double-block.mt-4 { margin-top: 0 !important; }
    .sidebar-content.list-post .item { display: block; }
    .main-footer.section.pb-5 { padding-bottom: 0 !important; }
    img.img-kids { width: 100%; margin: 20px 0; }
    .main-footer .img-kid { display: none; }
    .all-sibar .sidebar-content.list-post, .menu-footer-new #footer-menu { display: grid; grid-template-columns: 1fr 1fr; }
    .new-heading h3 { font-size: 20px; }
    .container-wrapper { padding: 15px; }
    .property-image.mobile-slider { display: block; height: unset;
        .gallery_item { img { height: 50vh; width: 100%; object-fit: cover; }
        }
        .owl-nav { display: none; }
    }
    .property-image { display: none; }
    .main-footer.section { .form-subscription form { display: grid; grid-template-columns: 7fr 3fr;
            input { margin: 0; border: 1px solid rgba(250, 87, 125, 0.75); border-radius: 0px; }
            button { border-radius: 0px; }
        }
        .footer-social-icon { ul { justify-content: center;
                li { padding: 10px; }
            }
        }
    }
    .new-title { margin-bottom: 0.5rem; }
    .new-gl-header { text-align: center;
        img { width: auto; height:65px; }
        h3 { font-size: 20px; }
        &:before { display: none; }
    }
    .cate-thumb img { width: 100%; }
    .intro-sections .cate-headding h1, .intro-sections .cate-headding h2 { font-size: 100px; }
}
@media screen and (max-width:480px) {
    .basic-section .new-dessign .list-post .items { display: block; }
    .list-video { .all-video { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px; }
    }
    .intro-sections .cate-headding h1, .intro-sections .cate-headding h2 { font-size: 65px; }
    .list-video .thumbs-video i { top: 25%; }
    .main-footer .the-subtitle:after { width: 100%; }
    ul#footer-menu>li>a { font-size: 15px; }
    .new-post-blogs { padding-top: 0px; }
    .mobile-banner { display: block; }
    .bg-images { position: relative;
        .list-tags { display: block; margin-top: 10px; }
        .new-post-content { position: unset; }
        .list-post.double-block{position: relative;}
        .btn-submit.active { position: absolute; bottom: -15px; right: 0%; z-index: 11; display:inline-block; font-size: 13px; padding: .3rem 1rem; transform: translateX(0);}
    }
    .new-post-blogs { .new-gl-header { z-index: 1;
            .new-title { background-color: transparent; }
        }
    }
    .mobile-banner { position: absolute; top: 0; width: 100%; height: 80px;object-fit: cover; left: 0; right: 0; z-index: -1; }
    .post-item .post-thumbs span { display: none; }
    .post-item .post-contents .post-detail a.more-link.button { padding: 0; font-size: 11px; background: unset; color: #fa587d; }
    .post-item .post-contents .post-detail h2 { margin-top: 5px; }
    .post-item .post-contents .post-detail h2 a { height: unset; }
    .slider-home .list-slide .slide-item img { width:100%; height:auto; }
    .slider-tienich { margin-top: 0;
        .owl-nav { top: 0%; }
        .item img { height: 80px; }
    }
    .list-video.list-post .items img { height: 120px; }
    .list-video.list-post { display: block; box-shadow: unset; border: unset; padding: unset;
        .list-video .thumbs-video i { width: 50px; height: 50px; }
        .btn-submit.active { font-size: 13px; padding: .3rem 1rem; }
    }
    .list-post { background-color: #ffffffd4; padding-bottom: 10px !important; position: relative; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 15px; padding: 15px; border-radius: 20px; border: 1px solid #9696961a; -webkit-box-shadow: -5px -2px 5px -5px #969696bf; box-shadow: -5px -2px 5px -5px #969696bf; -moz-box-shadow: -5px -2px 5px -5px rgba(0, 0, 0, 0.23);
        .items { display: block; margin-bottom: 0;
            h4 { font-size: 15px; margin-top: 10px;
                a { line-height: 22px; font-size: 13px;}
            }
            .items-cont { display: none; }
            img { height: 120px; }
        }
    }
    .global-bg { background-image: unset !important; }
    section.section-slider .list-post { display: block; }
    .global-bg .new-post-content .list-post { background-color: #ffffffd4; padding: 0; box-shadow: none; border: none;}
    .sub-cate-title h4, .sider-mobile .new-siderbar .heading-sidebar h3 { font-size: 17px; }
    .sub-category, .sub-sliders { .owl-dots { display: none; }
    }
    .new-gl-header .new-title { background-color: transparent; }
    .btn-submit.active { font-size: 13px; padding: .3rem 1rem; }
    .new-gl-header { padding: 10px 0 5px; }
    .link-tienich { display: block; text-align: center; margin-bottom: 30px; }
    .new-siderbar .heading-sidebar { padding: 5px 0; }
    .basic-section .list-post { display: block; }
}
