#snackbar {
    visibility: hidden;
    min-width: 250px;
    height: max-content;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1500;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
    font-size: 17px;
    box-shadow: 1px 1px 13px 4px #33333321;
    &.succeed {
        visibility: visible;
        background-color: $color;
        -webkit-animation: fadein 0.5s 2.5s;
        animation: fadein 0.5s 2.5s;
    }
}