.ads--banner{
    .banner__contain{
        margin: 20px 0px;
        &>a{
            &>img{
                width: 100%;
                height: auto;
            }
        }
    }
    &.top--banner{
        .banner__contain{
        margin: 0px;
        margin-bottom: 20px;        
        }        
    }
}
