/* Theme: Day Con kieu nhat. */
/* Mixin*/
$xs: 480px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1920px;
$xlg: 1280px;
$xxlg: 1366px;
$color: #e56a7e;
@mixin MediaQuery($type) {
    @if $type==$xs {
        @media screen and (max-width: $xs) {
            @content;
        }
    }
    @else if $type==$sm {
        @media screen and (max-width: $sm) {
            @content;
        }
    }
    @else if $type==$md {
        @media screen and (max-width: $md) {
            @content;
        }
    }
    @else if $type==$lg {
        @media screen and (max-width: $lg) {
            @content;
        }
    }
    @else if $type==$xlg {
        @media screen and (max-width: $xlg) {
            @content;
        }
    }
    @else if $type==$xxlg {
        @media screen and (max-width: $xxlg) {
            @content;
        }
    }
    @else if $type==$xl {
        @media screen and (max-width: $xl) {
            @content;
        }
    }
    @else {
        @media screen and (max-width: $type) {
            @content;
        }
    }
}

@import "scss/form-submit";
@import "scss/toast";
/*Mixin*/
// <====== Style Genneral ======>

//@font-face { font-family: Roboto; src: url("../libs/font/Comfortaa.ttf"); font-style: normal; font-weight: normal; font-display: swap }
@font-face { font-family: LovepenRegular; src: url("../libs/font/LovepenRegular.otf"); font-style: normal; font-weight: normal; font-display: swap }
@font-face { font-family: LovepenExtras; src: url("../libs/font/LovepenExtras.otf"); font-style: normal; font-weight: normal; font-display: swap }
html, body {
  font-size: 16px; letter-spacing: 0.8px; line-height: 1.5; font-family: Arial; overflow-x: hidden;
  @include MediaQuery($md){
    font-size: 18px; letter-spacing: 0.9px; line-height: 2;
  }
}
* { padding: 0; margin: 0; list-style: none; border: 0; outline: none; box-sizing: border-box; }
ul { margin: 0; }
.section { padding: 20px 0 }
html a { color: #333;
    &:hover { cursor: pointer; text-decoration: unset; color: $color; transition: 0.3s; }
}
button { &:focus { outline: 1px dotted; outline: unset; }
}
.cate-thumb img { width: 600px; height: auto; }
.pt-10 { padding-top: 100px; }
.main-nav__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include MediaQuery($xs){
      flex-wrap: wrap;
  }
  .img-logo{
    width: 20%;
    &>a{
        width: 60px;
        display: block;
    }
    @include MediaQuery($lg){
        &>a{
            width: 40px;
            display: block;
            img{
                width:40px;
                height: auto;
            }
        }
        width: 62%;
        @include MediaQuery($xs){
            width: 5%;
        }
        display:flex;
        justify-content: space-between;
        align-items: center;
        &-search-input{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:0px 5px;
            border: 1px solid #e56a7e;
            border-radius: 20px;
            height: 32px;
            position: relative;
            @include MediaQuery($lg){
                width: 78%;
            }
            @include MediaQuery($xs){
                width: 85%;
            }
            input{
                font-size:12px;
                width:85%;
            }
            label{
                width:15%;
                font-size: 16px;
                margin-bottom: 0;
                line-height: 0.2;
                color: #e56a7e;
            }
            .img-logo-search-input-result{
                position:absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                background: #fff;
                font-size: 12px;
                border-radius: 20px;
                box-shadow: 1px 1px 9px 1px #9e9e9e59;
                overflow-y: auto;
                p{
                    max-height: 26px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    line-height: 2.8;
                    margin-bottom: 0px;
                }
                img{ 
                    width: 40px;
                    height:auto;
                }
            }
        }
    }
}
.main-nav__item{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
    @include MediaQuery($lg){
        width: 87%;
        .login-item a{
            font-size: 12px!important;
        }
    }
    @include MediaQuery($xs){
        width: 68%;
    }
    .search-form{
      margin-right: 30px;
      width: 70%;
      form{
        width: 100%;
        input{
          padding: 5px 10px;
        }
        button{
          top: 23.5px;
          right: 13px;
        }
      }
      @include MediaQuery($md){
        display: none;
      }
    }
    .main-nav__social{
      &>a{
        display: inline-block;
        line-height: 38px;
        font-size: 25px;
        color: #e56a7e;
        background-color: transparent;
        border-radius: 2px;
        text-align: center;
        margin: 10px 0;
        @include MediaQuery($lg){
            font-size:20px;
        }
        @include MediaQuery(320px){
            font-size:16px;
        }
        &:hover{
          color: unset;
        }
      }
    }
  }
}
.fixed-top.not-home{
  #searchsss{
    display: none;
  }
  .main-nav{
    background: #e56a7e;
    .main-menu-wrapper{
      #menu-destop{
        #header-menu{
          &>.menu-item{
            &>a{
              color: #fff;
              &>i{
                font-size: 25px;
              }
              &::after{
                content: "";
                display: block;
                width: 0%;
                height: 2px;
                background: #fff;
                transition: all 0.25s ease-in-out;
              }
              &:hover{
                &::after{
                  width: 100%;
                }
              }
            }
            &.current-menu-item{
              &>a{
                &::after{
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    @include MediaQuery($md){
      background: transparent;
    }
  }
}
.fixed-top.not-home.fixed-header{
  #searchsss{
    display: block;
    .acction-search{
      &>i{
        color: #fff!important;
      }
    }
  }
  .main-nav__header{
    display: none;
    @include MediaQuery($lg){
        display:flex;
    }
    @include MediaQuery($xs){
        flex-wrap: wrap;
    }
  }
  .header-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.img-logo img { width: 180px; }
.display-none { display: none !important; }
.thumb-meta { display: none !important; }
.text-center { text-align: center!important; }
.grid-2-col{ grid-column: 1/spans 2;}
.text-right { text-align: right!important; }
.dp-none{
    @include MediaQuery($xs){
        display: none!important;
    }
}
.dt-none{
    @include MediaQuery($lg){
        display: none!important;
    }
}
.dp-flex{
    @include MediaQuery($xs){
        display: flex!important;
    }
}
.cut-text a{
    @include MediaQuery($xs){
        top: 8px !important;
        padding: 3px 6px!important;
    }
}
.overlays { position: relative;
    &:after { content: ""; position: absolute; left: 0; right: 0; bottom: 0; height: 65%; transition: opacity 0.2s; background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.75)); -webkit-backface-visibility: hidden; backface-visibility: hidden; }
}
.global-bg { background-repeat: no-repeat; background-size: cover; padding: 100px 0; background-position: top;
    .new-post-content .list-post, .new-gl-header .new-title { background-color: transparent; }
    .new-post-content .list-post .items img { border: 3px solid #fff; }
}
.container-wrapper { background: #ffffff; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 2px; padding: 30px; }
.global-heading { position: relative; font-size: 17px; padding: 0 0 15px; border-bottom: 2px solid rgba(0, 0, 0, 0.1); margin-bottom: 20px;
    &:before { content: ""; width: 0; height: 0; position: absolute; bottom: -5px; left: 0; border-left: 0; border-right: 5px solid transparent; border-top: 5px solid #2c2f34; }
    &:after { content: ""; background: #2c2f34; width: 40px; height: 2px; position: absolute; bottom: -2px; left: 0; }
    h5 { font-size: 15px; }
}
.modal-show { position: fixed; }
.meta-item { color: #767676; font-size: 11px; }
.container-wrapper { background: #ffffff; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 2px; padding: 30px; }
.block-item { display: block !important; }
.heathy-slider .block-item { a.more-link.button { position: relative !important; }
}
.section.recent-post { .more-link { position: relative !important; }
}
.haft-bg { background-repeat: no-repeat; background-size: 50%; background-position: bottom;
    &.list-post { .block_2, .block_3 { .items { .items-des { padding: 0 15px;
                    img { border: 5px solid #fff; }
                    a, .items-cont, .meta .meta-item { color: #fff; }
                }
            }
        }
    }
}
.flex-center { display: flex; justify-content: center; align-items: center; }
.flex-between { display: flex; justify-content: space-between; align-items: center; }
.flex-end { display: flex; justify-content: flex-end; align-items: center; }
.flex-start { display: flex; justify-content: flex-start; align-items: center; }
.bg-gray { background-color: #fafafa; }
.bg-white { background-color: #fff; }
.loadmore-ajax { a { color: #000000; padding: 2px 8px 3px 8px; transition: 0.3s; border-radius: 2px; border: 1px solid lightgray;
        &:hover { color: #fff; background-color: $color; transition: 0.3s; }
        i { font-size: 13px; }
    }
}
.btn { display: inline-block; font-weight: 400; text-align: center; white-space: nowrap; vertical-align: middle; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; border: 1px solid transparent; padding: .375rem .75rem; font-size: 1rem; line-height: 1.5; border-radius: .25rem; transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out }
.btn-submit { cursor: pointer; color: #212529; background-color: #f8f9fa; border-color: #f8f9fa;
    &.active { 
        font-weight: 600;
        font-size: 16px;
        padding: 10px 15px;
        text-align: center;
        background-color: transparent;
        color: #e56a7e;
        box-shadow: none;
        border-radius: 10px;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 10px 15px;
        line-height: 1.5;
        outline-color: transparent;
        box-shadow: none !important;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        &:after{
            content: "";
            display: block;
            height: 1.3px;
            width: 0%;
            background: #737272;
            -webkit-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
        }
        &:hover{
            color: #737272;
        }
        &:hover:after{                
            width: 100%;
        }
    }
}
#Comments { display: none; }
// <====== Style Genneral ======>

#top-nav { background-color: #ffffff; position: relative; z-index: 10; line-height: 35px; border: 1px solid rgba(0, 0, 0, 0.1); border-width: 1px 0; color: #2c2f34; }
.top-nav { display: flex; min-height: 35px;
    .top-time { display: flex; align-items: center; float: left; padding-right: 10px;
        p { font-size: 13px; margin: 0; }
        i { margin-right: 5px; }
    }
    .top-menuleft { float: left; display: flex; position: relative; align-items: center; }
    .top-menuright { float: right; flex-grow: 1;
        ul { display: flex; align-items: center; float: right; padding-right: 10px;
            li { &.search-bar { width: auto; border: solid rgba(0, 0, 0, 0.1); border-width: 0 1px; position: relative; }
                input { border: 0; margin-bottom: 0; line-height: inherit; width: 100%; background: transparent; padding: 0 35px 0 13px; border-radius: 0; box-sizing: border-box; font-size: inherit; }
                button { position: absolute; right: 0; top: 0; width: 40px; line-height: inherit; color: #777777; background: transparent; font-size: 15px; padding: 0; transition: color 0.15s;
                    &:hover { i { color: $color; }
                    }
                }
                a { i { color: #000000; padding: 0 5px; }
                }
            }
        }
    }
}
.not-home { .menu li a { color: $color; }
}
.menu { li { display: block; float: left; position: relative;
        a { position: relative; color: #fff; font-size: 13px; font-weight: 400; padding: 0 5px;
            &:hover { text-decoration: unset; }
        }
        &.menu-item-has-children { >a { padding-right: 25px; }
            >ul { display: none; position: absolute; background: #ffffff; z-index: 999;
                li { ul { display: none; position: absolute; background: #ffffff; z-index: 999; left: 180px; top: 0; }
                }
            }
            &:hover { display: block; float: left; position: relative;
                >a { color: $color;
                    &:before { border-top-color: $color; }
                }
                >ul { border-top: 2px solid $color; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); display: block;
                    li { display: block; float: left; width: 180px;
                        a { color: #2c2f34; width: 180px; line-height: 20px; padding: 7px 15px;
                            &:hover { color: #fff; }
                        }
                    }
                }
            }
        }
    }
}
.main-nav { border-width: 1px 0;
    .menu li.menu-item-has-children.mega-cat { position: unset; }
    .main-menu { .menu-sub-content { padding: 0; position: absolute; box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15); left: 0; width: 100%;
            ul { width: 100%; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                li.mega-menu-post { width: 20% !important; padding: 0 10px;
                    a { }
                }
            }
        }
    }
    .main-menu-wrapper { display: flex; justify-content: space-between; align-items: center; position: relative;
        ul#loaded-1 { padding: 20px 10px; z-index: 99; }
        .menu { >li { &.menu-item-has-children { &>a { &:before { right: 20px; }
                    }
                }
                border-radius: 25px;
                &.current-menu-item { position: relative; }
                &:hover, &.current-menu-item { background-color: $color;
                    >a { color: #FFFFFF;
                        &:before { border-top-color: #ffffff; }
                    }
                }
                a { padding: 7px 10px; text-transform: uppercase; font-weight: 300; font-size: 12.5px; display: block; }
                ul { li { a { font-size: 15px; text-transform: unset; }
                    }
                }
            }
        }
    }
}
.post-thumbnail { .post-thumb { padding: 0 !important; margin: 0; width: 100% !important;
        img { width: 100%; display: block; height: auto; }
    }
}
.post-details { .post-box-title { a { margin: 0 !important; padding: 10px 0 !important; }
    }
    .post-meta.clearfix { line-height: 0;
        span { font-size: 12px; color: rgba(0, 0, 0, 0.5); }
    }
}
.property-image { width: 100%; display: grid; grid-template-columns: 50fr 25fr 25fr; grid-gap: 0.2vh; background-color: #fff; height: 50vh;
    .gallery_item { overflow: hidden; position: relative;
        &.small-thumb { &:hover { .thumb-content { .thumb-desc { opacity: 1; max-height: 100px; margin-top: 5px; transition: opacity 0.4s ease-out, max-height 0.9s ease-out; }
                }
            }
            .thumb-content { .thumb-desc { color: #ffffff; max-height: 0; line-height: 18px; overflow: hidden; opacity: 0; transition: opacity 0.4s ease-out, max-height 0.4s ease-out, margin 0.4s; }
                .thumb-title { line-height: 17px;
                    a { color: #fff; font-size: 17px; }
                }
            }
        }
        &:after { .thumb-content .thumb-title a { color: #ffffff; }
        content: ""; position: absolute; left: 0; right: 0; bottom: 0; height: 65%; transition: opacity 0.2s; background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.75)); -webkit-backface-visibility: hidden; backface-visibility: hidden; }
        &:first-child { grid-row: 1 / 3;
            img { height: 50vh; }
        }
        img { height: 25.9vh; width: 100%; object-fit: cover; }
        .sticker { position: absolute; top: 20px; left: 20px;
            a { padding: 3px 5px; background-color: #9b59b6; color: white; font-size: 11px; transition: 0.3s; border-radius: 2px;
                &:hover { cursor: pointer; text-decoration: unset; }
            }
        }
        .thumb-content { position: absolute; bottom: 0; left: 0; padding: 20px 20px; z-index: 3; width: 100%; transition: bottom 0.3s; -webkit-backface-visibility: hidden; backface-visibility: hidden; color: #ffffff;
            .thumb-meta { i { pointer-events: none; width: 22px; height: 22px; line-height: 22px; text-align: center; font-size: 12px; background: #F64E4E; color: #ffffff; border-radius: 100%; z-index: 4; }
                span { font-size: 13px; }
            }
            .thumb-title { padding: 10px 0;
                a { color: #ffffff;
                    &:hover { cursor: pointer; text-decoration: unset; }
                }
            }
            .thumb-desc { font-size: 13px; }
        }
    }
}
.recent-post { .link-loadmore { border: 1px solid rgba(0, 0, 0, 0.1); height: 50px; line-height: 50px; position: relative; text-align: center; display: block; font-weight: 600; cursor: pointer; clear: both; margin: 25px -30px -30px; border-width: 1px 0 0;
        &:hover { color: $color; }
    }
}
.global-title { position: relative; font-size: 20px;
    h3 { color: $color; font-weight: 400; }
}
// style Post item
.double-block { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 15px; }
.post-item { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px; margin-bottom: 20px;
    .post-thumbs { position: relative;
        span { position: absolute; right: 10px; bottom: 10px; background-color: $color; color: #ffffff; padding: 3px 5px; font-size: 11px; z-index: 1; }
        img { width: 100%; height: 200px; object-fit: cover;
            &:hover { opacity: 0.8; }
        }
    }
    .post-contents { position: relative;
        .post-detail { h2 { line-height: 23px; margin-top: 10px;
                a { height: 50px; color: #333; font-size: 18px; line-height: 25px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
                    &:hover { color: $color; }
                }
            }
            .post-excerpt { color: #666666; font-size: 13px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; }
            a.more-link.button { width: auto; position: absolute; bottom: 0; left: 0; background-color: $color; color: #fff; padding: 7px 15px; margin: 0; font-weight: normal; font-size: 12px; display: inline-block; text-align: center; line-height: 21px; border: none; cursor: pointer; text-decoration: none; vertical-align: middle; transition: 0.3s; border-radius: 2px; -webkit-appearance: none; -moz-appearance: none; appearance: none;
                &:hover { color: $color; background: #fff; border: 1px solid $color; transition: all .2s; }
            }
        }
    }
}
.meta { display: flex; justify-content: space-between; align-items: center;
    .meta-author { display: flex; justify-content: space-between; align-items: center;
        .meta-item { margin-right: 10px;
            a { color: #333; }
        }
        img { border-radius: 50%; width: 30px; height: 30px; margin-right: 5px; }
    }
    .meta-social { display: flex; justify-content: space-between; align-items: center;
        .meta-item { margin-left: 10px; }
    }
    .meta-item { color: #767676; font-size: 11px;
        i { margin-right: 5px; }
        &.read { color: red; }
    }
}
.tabs-menus { .tabs-contents { border: 1px solid rgba(0, 0, 0, 0.1); border-top: unset; padding: 20px; }
    .tab-lists { display: grid; grid-template-columns: 1fr 1fr;
        button { background: rgba(0, 0, 0, 0.02); display: block; text-align: center; border: 1px solid rgba(0, 0, 0, 0.1); font-size: 15px; padding: 20px 0;
            &:focus { outline: 1px dotted; outline: unset; }
            &.active { background: transparent; border-bottom: unset; }
        }
    }
}
.itempost-small { display: grid; grid-template-columns: 4fr 6fr; grid-gap: 15px; margin-bottom: 15px; position: relative;
    .top-trend { z-index: 1; position: absolute; top: -10px; left: -15px; color: #fff; background: $color; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; border-radius: 50%; font-weight: bold; border: 2px solid #fff;
        &.top-1 { -webkit-transform: scale(1.4, 1.4); -ms-transform: scale(1.4, 1.4); transform: scale(1.4, 1.4); }
        &.top-2 { -webkit-transform: scale(1.3, 1.3); -ms-transform: scale(1.3, 1.3); transform: scale(1.3, 1.3); }
        &.top-3 { -webkit-transform: scale(1.2, 1.2); -ms-transform: scale(1.2, 1.2); transform: scale(1.2, 1.2); }
        &.top-4 { -webkit-transform: scale(1.1, 1.1); -ms-transform: scale(1.1, 1.1); transform: scale(1.1, 1.1); }
    }
    &:last-child { margin-bottom: 0px; }
    img { width: 100%; height: 75px; object-fit: cover;
        &:hover { opacity: 0.8; }
    }
    h4 { line-height: 18px;
        a { color: #333; font-size: 14px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
            &:hover { color: $color; }
        }
    }
    .meta-item { font-size: 12px; color: #767676; }
}
.mobile-banner { display: none; }
.item-recomment { display: grid; grid-template-columns: 3fr 7fr; grid-gap: 15px; margin-bottom: 15px;
    &:last-child { margin-bottom: 0px; }
    img { width: 70px; height: 70px; object-fit: cover; border-radius: 50%; }
    a, p { font-size: 13px; color: #333; font-weight: 600; }
}
.slider-comment { padding: 30px 0;
    .list-slider { position: relative;
        .owl-nav { position: absolute; width: 100%; top: 85px; display: flex; justify-content: space-between;
            button { background-color: #fff; padding: 10px 10px !important; transition: 0.3s; border-radius: 2px; border-top-left-radius: 0; border-bottom-left-radius: 0;
                &:hover { transition: 0.3s; background: $color; color: #fff; }
                span { font-size: 25px; }
            }
        }
    }
    .meta-item { color: #fff;
        i { color: #fff; }
    }
    img { width: 100%; height: 220px; object-fit: cover; }
    .item-slider { position: relative; z-index: 1;
        .thumbs-slide { position: absolute; bottom: 0; left: 0; padding: 20px; z-index: 1;
            a { color: #fff; font-size: 14px; }
        }
    }
}
.follows-us { background: #ffffff; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 2px; padding: 30px;
    .social-icons { display: inline-block;
        .social-icons-item { float: left; width: 50%; margin-left: 0; padding: 10px 5px 0; border-bottom: 0;
            a { display: block; padding: 10px; position: relative; overflow: hidden; display: flex; align-items: center;
                i { margin-right: 10px; float: left; color: #ffffff; background-color: #2c2f34; width: 30px; height: 30px; line-height: 30px; text-align: center; font-size: 15px; transition: 0.3s; border-radius: 50%;
                    &.fa-facebook { background-color: #5d82d1; }
                    &.fa-twitter { background-color: #40bff5; }
                    &.fa-dribbble { background-color: #c13584; }
                    &.fa-youtube { background-color: #ef4e41; }
                }
                .followers { color: #2c2f34; display: grid;
                    span { &.followers-name { font-size: 10px; }
                        &.followers-num { font-size: 13px; font-weight: 600; }
                    }
                }
            }
        }
    }
}
.posts-list-items { position: relative; padding-left: 15px;
    &:before { content: ""; position: absolute; left: 0; top: 0; width: 2px; height: 100%; background: #e6e6e6; }
    li { padding-top: 10px;
        &:first-child { padding-top: 0; }
        a { .meta-item { position: relative;
                &:before { content: ""; width: 12px; height: 12px; background: #e6e6e6; border: 3px solid rgba(255, 255, 255, 0.8); position: absolute; left: -20px; display: inline-block; vertical-align: middle; border-radius: 50%; -webkit-transform: translateZ(0); transform: translateZ(0); -webkit-backface-visibility: hidden; backface-visibility: hidden; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; transition-duration: 0.3s; }
                i { font-size: 10px; color: #767676; line-height: 12px; margin-bottom: 5px; }
            }
            h4 { color: #333; font-size: 14px; }
            &:hover { .meta-item { &:before { background-color: $color; transition: all .25s; }
                }
                h4 { color: $color; transition: all .25s; }
            }
        }
    }
}
.main-footer { background-color: #fff;position: relative;
    .container{
      padding: 20px 12px;
      box-shadow: 0px -2px 0px 0px #fcdedc;
      .the-subtitle { position: relative; padding: 20px 0; margin-bottom: 20px; font-size: 16px; color: $color; font-weight: 600;
          &:after { content: ""; background: #2c2f34; width: 40px; height: 2px; background-color: $color; position: absolute; bottom: -2px; left: 0; }
      }
      .textwidget { font-size: 13px; color: #333;
          i { min-width: 15px; color: $color; }
      }
      .menu-footer { li { padding-bottom: 5px;
              a { font-size: 13px; color: #2c2f34; }
          }
      }
    }
    .img-kid { position: absolute; width: 25%; bottom: 0; right: 0; left: 0; margin: 0 auto; }
}
.form-subscription { input { border: 1px solid rgba(0, 0, 0, 0.1); color: #ffffff; font-size: 12px; outline: none; padding: 10px; margin: 10px 0; line-height: 23px; border-radius: 2px; transition: background 0.3s, border 0.3s; max-width: 100%; background-color: transparent; width: 100%; }
    button { border-color: rgba(255, 255, 255, 0.1); background-color: $color; color: #FFFFFF; transition: 0.3s; border-radius: 2px; width: 100%; font-weight: bold; padding: 10px; }
}
.coppy-right { background: #161619; padding: 20px 0; line-height: 32px; text-align: center; color: #fff;
    p { margin: 0; font-size: 13px; }
}
#myBtn { display: none; position: fixed; bottom: 20px; right: 15px; z-index: 99; font-size: 18px; border: none; outline: none; background-color: #333333; color: white; cursor: pointer; padding: 2px 10px 5px 13px; border-radius: 2px; }
.top-news-box { .title-hot { background: #f05555; display: inline-block; color: #fff; padding: 12px; border-radius: 2px; font-size: 13px; font-weight: 600; float: left; }
    .news-item { background: #ffffff; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 2px; padding: 10px;
        a { line-height: 22px; font-size: 13px; color: #333; font-weight: 600; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; }
    }
    .news-slide { position: relative;
        .owl-nav { position: absolute; top: 0; right: 0;
            button { background-color: #fff; padding: 10px 18px !important; transition: 0.3s; border-radius: 2px; border: 1px solid rgba(0, 0, 0, 0.1);
                &.owl-prev { border-right: unset; }
                &:hover { background-color: $color; color: #ffff; transition: all .25s; }
            }
        }
    }
}
.footer-social-icon { ul { li { padding: 0px 5px;
            a { i { line-height: 38px; font-size: 25px; color: $color; background-color: transparent; border-radius: 2px; text-align: center; margin: 10px 0; }
            }
        }
    }
}
.section.healthy-life { position: relative;
    .owl-dots { display: none; }
    .owl-nav { position: absolute; top: -65px; right: 0;
        button { color: #000000; padding: 2px 8px 3px 8px !important; transition: 0.3s; border-radius: 2px; border: 1px solid lightgray; margin-left: 5px;
            &:hover { transition: 0.3s; background: #fa587d; color: #fff; }
        }
    }
}
.quatrang { display: flex; margin: 50px 0px 20px; align-items: center; justify-content: center;
    a, span { border: 1px solid $color; padding: 5px 9px; border-radius: 2px; margin: 0 5px;
        &.current { background-color: $color; color: #fff; transition: all .25s ease; }
    }
}
.search-form { form { padding: 20px 0; position: relative; width: 60%;
        input { width: 100%; border: 1px solid $color; font-size: 13px; outline: none; padding: 10px; margin: 0; line-height: 23px; border-radius: 30px; transition: background 0.3s, border 0.3s; max-width: 100%; background-color: transparent;
            &::placeholder { color: #9f9f9f; }
        }
        button { background-color: transparent; border-color: #fa587d; color: #FFFFFF; transition: 0.3s; border-radius: 2px; font-weight: bold; position: absolute; top: 30px; right: 20px;
            i { color: $color; }
        }
    }
}
.new-gl-header.tags-title { img { width: 40px; }
    h3 { text-transform: unset; }
}
.aligncenter img { margin: 0 auto; display: block; }
.wp-caption { height: auto; max-width: 100%; width: 100% !important; }
.wp-caption img { max-width: 100%; height: auto; }
.post-single.section img { max-width: 100%; height: auto; }
.fb-page.fb_iframe_widget { width: 100% !important; }
#breadcrumbs { a { }
    i { margin-right: 5px; }
    .breadcrumb_last { color: $color; }
}
.fb-comments iframe { width: 100% !important; }
.form-contact { input, textarea { background: #ffffff; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 2px; padding: 10px; width: 100%;
        &.wpcf7-submit { color: $color; border: 1px solid $color; border-radius: 2px;
            &:hover { color: #ffffff; background: $color; transition: all .25s ease-in-out; }
        }
    }
}
.fb-like { margin-right: 10px; }
section.section.tabs-lists a.more-link.button { display: none !important; }
.tabs-news .post-item .post-thumbs img { height: 140px; }
.ads-sticky { position: -webkit-sticky; position: sticky; top: 10px; }
.table-img { text-align: center;
    img { width: 40px; height: auto; }
}
.tab-listsss { display: block; -webkit-overflow-scrolling: touch; overflow-x: auto; overflow-y: hidden; white-space: nowrap; }
.property-image .gallery_item.small-thumb .thumb-content .thumb-title a { overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
#ez-toc-container a { color: #007bff; }
#post-container.container-wrapper ol { background: lavenderblush; padding: 15px 20px; border-left: 5px solid #fa577d; }
#post-container.container-wrapper ol li a { color: #0e7dff; font-size: 15px; }
#post-container.container-wrapper ol li { padding: 2px 0; }
#post-container.container-wrapper ul { padding-left: 20px; }
#post-container.container-wrapper ul li { list-style: disc; }
// New home page
.header-home { background-color: transparent; -webkit-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out; }
.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030;
    &.fixed-header { background-color: #fff; -webkit-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;
        .menu li a { color: #2c2f34; }
        .menu li.menu-item-has-children>a:before { border-top-color: #2c2f34; }
        .menu li.menu-item-has-children:hover>ul li a { color: #2c2f34; }
        .main-nav { border-bottom: 1px solid rgba(0, 0, 0, .1); border-width: 1px 0;background: #e56a7e;
          @include MediaQuery($md){
            background: transparent;
          }
         }
    }
}
.slider-home { position: relative;
    .list-slide {
        .slide-item { img { height: 50vh; object-fit: cover; width: 100%; }}
            .owl-nav{
                position: absolute;
                top: 35%;
                width: 100%;
                button{
                    &.owl-next {
                        float: right;
                    }
                    span{
                        font-size: 100px;
                        color: #fff;
                        padding: 0 30px;
                    }
                }
            }

    }
    .authentical-tab { text-align: center; position: absolute; top: 25vh; width: 100%;
        ul { display: inline-flex; padding: 20px 0;
            li { padding: 0 5px;
                a { color: #ffffff; font-weight: bold; font-size: 20px; }
            }
        }
        .form-sent-mail { input { padding: 7px 15px; background-color: transparent; border: 2px solid #fff; border-radius: 25px; color: #fff; width: 30%;
                &::placeholder { color: #fff; }
            }
            button { padding: 7px 30px; background-color: #e5697c8f; border: 2px solid #fff; border-radius: 25px; color: #fff; text-transform: uppercase; }
        }
    }
}
.new-heading { text-align: center;
    h3 { color: $color; font-size: 25px; font-weight: 400; text-transform: uppercase; }
}
.slider-tienich, .sliders-tienichs { margin-top: 18px;
    .owl-stage-outer{
        z-index: 10000;
        width: 95%;
        margin: 0 auto;
        @include MediaQuery($md){
            width: 90%;
        }
    }
    .owl-nav { display: block !important; position: absolute; top: 20%; width: 100%; z-index: 10;
        button { &.owl-next { float: right; }
            span { color: $color; font-size: 60px; }
        }
    }
    .item { text-align: center;
        img { height: 80px; width: auto; margin: 0 auto; }
        h4 { 
            a { 
            margin: 0 auto;
            margin-top: 15px; 
            color: #0074c7; 
            display: block; 
            transition: all 0.25s ease-in-out;
            @include MediaQuery($md){
                font-size: 14px;
                max-width: 62%;
            }
            }
        }
        &:hover{
          a{
            color: #24a4a7;
          }
        }
    }
}
.tien-ich { display: flex; justify-content: center;
    .item { text-align: center; margin: 0 30px;
        img { height: 130px; width: auto; margin: 0 auto; }
        h4 { font-weight: 400;
            a { margin-top: 15px; color: $color; display: block; font-size: 17px; }
        }
    }
}
.new-gl-header { position: relative;
    .new-title { align-items: center; background-color: #fff; display: inline-flex; padding-right: 20px; }
    img { width: 90px; height: auto; }
    h3 { a { color: $color; font-weight: 600}
    color: $color; font-size: 25px; margin-left: 15px; margin-bottom: 0; font-weight: 400; text-transform: uppercase; }
}
.list-post { &.sidebar-content { padding-left: 0px; }
    .items { display: grid; grid-template-columns: 4fr 6fr; grid-gap: 20px; margin-bottom: 25px; position: relative;
        img { border-radius: 15px; width: 100%; height: 158px; object-fit: cover; }
        h4 { color: $color; font-size: 17px; }
        a { color: #0056b3 ; font-weight: 600; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; transition: all 0.25s ease-in-out}
        .items-cont { font-size: 14px; color: #2c2f34; }
    }
}
.list-tags { padding: 15px 0;
    @include MediaQuery($xs){
        padding: 5px 0;
    }
    strong { color: #9e9e9e; }
    ul { li { display: inline-block;
            a { color: #333;
                font-size: 16px;
                display: inline-block;
                padding: 5px 10px;
                margin-right: 3px;
                margin-bottom: 5px;
                border: 1px solid $color;
                border-radius: 13px;
                background: #fff;
                transition: all 0.25s ease-in-out;
                &:hover {
                  background: $color;
                  color: #fff;
                }
                @include MediaQuery($xs){
                    font-size: 10.5px;
                    padding: 5px;
                    margin-right: 0px;
                    line-height: 1.4;
                }
            }
            @include MediaQuery($xs){
                display: none;
                &:nth-child(1),&:nth-child(2),&:nth-child(3){
                    display: inline-block;
                }
            }
        }
        @include MediaQuery($xs){
            max-height: 38px;
            overflow: hidden;
        }
    }
    .btn.read--more{
        display: none;
        @include MediaQuery($xs){
            display: block;
            background-color: #e56a7e;
            border-color: #e56a7e;
            color: #fff;
            font-weight: 500;
            border-radius: 20px;
            padding: 4px 12px;
            font-size: 12px;
        }
    }
}
.new-siderbar { border-radius: 20px; margin-bottom: 10px!important;
    .heading-sidebar { text-align: center; background-color: $color; padding: 5px 0; border-radius: 20px 20px 0px 0px;
        h3 { color: #fff; font-size: 22px; text-transform: uppercase; font-weight: 400; margin-bottom: 0; }
    }
    .sidebar-content {
      padding: 20px;
      background-color: #ffffff;
      border: 1.5px solid #f7c3b5;
      border-radius: 0px 0px 20px 20px;
    }
    .list-post { .items { h4 { line-height: 20px; font-size: 15px;
                a { font-size: 15px;
                @include MediaQuery($md){
                    font-size: 13px;
                  }
                 }
            }
            img { height: 100px; }
            .items-cont { font-size: 13px; }
        }
    }
}
.new-post-content { position: relative;
    .list-post { background-color: #ffffffd4; z-index: 2;padding-bottom: 10px;
        @include MediaQuery($xs){
            padding: 0;
            box-shadow: none;
            border: none;
        }
    }
    .decor-img { position: absolute; height: auto; width: 65%; top: 0; right: 0; z-index: -1; background: transparent;
        &.center { width: 50%; right: 0; }
    }
}
.not-home { background-color: #fff !important; position: sticky;
    a { &:hover { color: #fff; }
        &:before { border-top-color: #2c2f34 !important; }
    }
    .sub-menu { a { &:hover { color: #fff; background-color: $color; }
        }
    }
}
.fixed-top.not-home.fixed-header{
  position: fixed;
}
.intro-sections { .cate-headding { h1, h2 { font-family: LovepenRegular; font-size: 150px; font-weight: 500; color: $color; line-height: 1; }
    }
    .cate-des { font-size: 15px; line-height: 1.7; }
}
.new-listtags { margin: 30px 0;
    ul { li { display: inline-block;
            a { color: #2c2f34; padding: 0.375rem 1rem; border-radius: 20px; border: 1px solid $color; display: block; margin: 5px 0; font-size: 13px;
                &:hover { color: #fff; background-color: $color; }
            }
        }
    }
}
.sub-cate-title { border-bottom: 1.5px dashed #fcc4b5; margin: 10px 0 30px;
    h4 { color: #2c2f34; font-size: 20px; text-transform: uppercase; font-weight: 400; margin-bottom: 0; padding: 10px 0; }
}
.new-post-cates { .new-gl-header:before { display: none; }
}
.sub-sliders { width: 90%; margin: 0 auto; }
.sub-slider, .sub-sliders { width: 70%; margin: 0 auto;
    .owl-nav { display: block !important; position: absolute; top: 10%; width: 130%; left: -15%; right: 115%;
        button { &.owl-next { float: right; }
            span { color: $color; font-size: 60px; }
        }
    }
}
.slide-items { .items-cont { font-size: 14px; color: #2c2f34; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
    img { border-radius: 15px; width: 100%; height: 170px; object-fit: cover; }
    h4 { margin-top: 10px;
        a { color: #fa587d; font-weight: 400; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
    }
}
.block-bg-img { padding: 10px 15px; background-repeat: no-repeat; background-size: cover; background-attachment: fixed;
    .new-gl-header { text-align: center;
        .new-title { background-color: transparent;
            h3 { color: #fff; }
        }
    }
    .new-post-content .list-post { background-color: transparent; }
    .list-post .items .items-cont { color: #fff; }
    .new-listtags ul li a { color: #fff; }
    .meta .meta-item { color: #fff; }
}
ul.list-music li { background: #f1f3f4; border-bottom: 1px solid gray; padding: 20px 0 10px;
    audio { width: 100%; background: #f1f3f4; height: 15px; color: gray; }
    span { padding: 0 20px; font-size: 13px; color: gray; }
}
.new-dessign { .list-post .items { display: block;
        .items-des { margin-top: 10px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; }
    }
}
// New single
.newgl-block { padding: 20px 30px; border-radius: 20px; border: 1px solid #9696961a; overflow: hidden; -webkit-box-shadow: -5px -2px 5px -5px #969696bf; box-shadow: -5px -2px 5px -5px #969696bf; -moz-box-shadow: -5px -2px 5px -5px rgba(0, 0, 0, 0.23);
    .meta-item { margin-right: 20px; color: #000; font-size: 15px; text-transform: capitalize;
        &.read { color: $color;
            i { margin-right: 3px; }
        }
    }
    .content { img { margin-bottom: 5px; max-width: 100%; height: auto; width: 100%; }
        ol { background: lavenderblush; padding: 15px 20px; border-left: 5px solid #fa577d;
            li { padding: 2px 0;
                a { color: #0e7dff; font-size: 15px; }
            }
        }
        .wp-caption-text { text-align: center; font-size: 13px; font-style: italic; }
        #ez-toc-container nav { padding-left: 20px;
            ul li { padding: 5px 0; list-style: disc;
                a { &:hover { color: $color; }
                }
            }
        }
    }
}
.new-title { color: #fa587d; font-weight: 300; font-size: 35px; line-height: 1.3; }
.tags-post { a {
    color: #333;
    font-size: 16px;
    display: inline-block;
    padding: 5px 10px;
    margin-right: 3px;
    margin-bottom: 5px;
    border: 1px solid $color;
    border-radius: 13px;
    background: #fff;
    transition: all 0.25s ease-in-out;
    &:hover {
        background: $color;
        color: #fff;
    }
}
}
.new-share { .share-icon { color: $color; }
}
.menu-footer-new { #footer-menu { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        .sub-menu { display: block;
            li { font-size: 14px;
                a { color: #333; font-size: 13px; font-weight: 400;
                    &:hover { color: $color; }
                }
            }
        }
    }
}
ul#footer-menu>li>a { position: relative; padding: 10px 0; margin-bottom: 0px; font-size: 17px; color: #fa587d; font-weight: 600; display: block; margin-bottom: 20px;
    &:after { content: ""; background: #2c2f34; width: 70px; height: 2px; background-color: #fa587d; position: absolute; bottom: -2px; left: 0; }
}
.list-video { .all-video { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-gap: 15px; }
    .thumbs-video { position: relative; cursor: pointer;
        i { position: absolute; color: #fff; top: 34%; font-size: 30px; margin: 0 auto; left: 0; right: 0; border: 2px solid #fff; display: flex; border-radius: 50%; width: 60px; height: 60px; align-items: center; justify-content: center; padding-left: 5px; z-index: 2; }
        .fancybox-media,.fancybox-media-public{
            border-radius: 15px;
            display: block;
            img{
                transform: scale(1);
                transition: all 0.4s ease-in-out;
            }
            &:after { 
                content: "";
                display: block;
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 1;
                background: #0000003d;
                border-radius: 15px;
            }
        }
        &:hover{ 
            .fancybox-media,.fancybox-media-public{
                img{
                    transform: scale(1.1);
                }
            }
        }
    }
    .items-video{ 
        .items-des a{
            text-align: center;
        }
    }
}
.sider-mobile { .new-siderbar { overflow: unset;
        .heading-sidebar { border-radius: 20px;
            h3 { font-size: 20px; }
        }
        .sidebar-content { background: transparent; box-shadow: none; margin-top: 18px; border-color: $color; }
    }
    .btn-submit.active { 
        background: #e56a7e;
        color: #fff;
        border: 1px solid #e56a7e;
        position: absolute;
        bottom: -15px;
        height: auto;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 11;
        display: block;
        width: 110px;
        font-size: 13px;
        padding: .3rem 1rem;
        border-radius: 20px;
    }
}
.link-tienich { display: inline-block; text-align: center;
    .icon { img { width: 130px; height: auto; }
    }
    &>a { text-transform: uppercase; background-color: #fc8685;border: 1px solid #fc8685;color: #fff; padding: 5px 10px; margin-top: 15px; display: inline-block; border-radius: 10px; transition: all 0.25s ease-out;
        @include MediaQuery($xs){
            background: #e56a7e;
            color: #fff;
            &:hover{
                background: #e56a7e;
                color: #fff;
            }
        }
    }
    &>a{
      &:hover{
        color: #333;
        background-color: #fff;
        border-radius: 20px;
      }
    }
}
div#menu-destop { display: flex; align-items: center;justify-content: space-between; width: 100%;
    #searchsss { position: relative;
        .acction-search { padding: 7px 10px;
            i { color: #fff; font-size: 25px; cursor: pointer;
                &:hover { color: $color; transition: 0.3s; }
            }
        }
    }
    .search-form { display: none; position: absolute; width: 400px; right: 0; top: 50px;
        form { width: 100%;
            #search { background-color: #fff; color: #2c2f34; }
        }
    }
}
.not-home { div#menu-destop #searchsss .acction-search i { color: $color; }
}
.fixed-top.fixed-header { div#menu-destop { #searchsss { .acction-search { i { color: #2c2f34; font-size: 25px; cursor: pointer;
                    &:hover { color: $color; transition: 0.3s; }
                }
            }
        }
    }
}
.background--white-border{
  background: #fff!important;
  padding: 20px 40px;
  border-radius: 20px;
}

.new-post-content{
  &>.list-post{
    &>.items{
      &:hover{
        &>.items-des{
          &>h4{
            &>a{
              color: #24a4a7;
            }
          }
        }
      }
    }
  }
}
.list-post .items{
  &:hover{
      a{color: #24a4a7!important;}
  }
}
b,strong{
    font-weight: bold;
}
.section{
    &.list-post{
        &>.items{
            grid-template-columns: 2fr 6fr;
        }
    }    
}
.new-post-content{
    &>.list-post{
        &>.items{
            grid-template-columns: 2fr 6fr;
        }
    }
}
.review__btn-more{
    &.xem--nhieu{
        position:unset!important;
        transform: none!important;
        text-align: center!important;
        .btn{
            background: #e56a7e;
            font-size: 14px;
            padding: 5px 10px;
            color: #fff;
            border: 1px solid #e56a7e;
            &:hover{
                color: #333;
                background: #fff;
            }
            @include MediaQuery($md){
                background: #e56a7e;
                color: #fff;
                border: 1px solid #e56a7e;
                position: absolute;
                bottom: -15px;
                height: auto;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 11;
                display: block;
                width: 110px;
                font-size: 13px;
                padding: .3rem 1rem;
                border-radius: 20px;
            }
        }
    }
}
.sider-mobile{
    .new-siderbar{
        @include MediaQuery($md){
            margin: 20px 0px!important;
        }
    }
}
.icon--home{
    font-size:30px!important;
}
#list-menu-mobile{
    &>li.menu-item:nth-child(1){
        display:none;
    }
}
.font-weight-bold{
    font-weight: 600!important;
}
.home,.category{
    .list-post{
        .items{
            display: none;
            &:nth-child(1), &:nth-child(2),&:nth-child(3){
                display: grid;
            }
        }
        &.list-video{
            .items{
                &:nth-child(1), &:nth-child(2),&:nth-child(3),&:nth-child(4){
                    display: block;
                }
            }
        }
        @include MediaQuery($xs){
            .items{
                display: none!important;
                &:nth-child(1), &:nth-child(2){
                    display: block!important;
                }
            }
        }
    }
}
.dckn-bottom-menu{
    .dckn-bottom-menu__container{
        display: flex;
        justify-content:center;
        align-items:center;
        background-color: rgb(229, 106, 126);
        padding:0px;
    }
    .dckn-menu-link{
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 600;
        color: #fff!important;
    }
}
.meta-item{
    font-size: 15px;
    color: #e56a7e;
}
.gg-close {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 40px
}
.gg-close::after,
.gg-close::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 16px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 8px;
    left: 1px
}
.gg-close::after {
    transform: rotate(-45deg)
}