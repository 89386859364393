.search-form{
    position: relative;
}
.searh-form__result__desktop{
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    background: #fff;
    font-size: 12px;
    border-radius: 20px;
    box-shadow: 1px 1px 9px 1px #9e9e9e59;
    overflow-y: auto;
    z-index: 100;
    &>a{
        font-size:13px;
        p{
            margin-bottom:0.9rem;
        }
    }
    &>a:hover{
        color:#29bac1;
    }
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 20px;
        margin: 5px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
        border-radius: 20px;
        margin: 5px;
    }
    
        /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #e56a7e;
        border-radius: 20px;
        margin: 5px;
    }
    
        /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #e56a7e;
        border-radius: 20px;
        margin: 5px;
    }
}