.position{
    &--relative{
        position: relative;
    }
}
.new--layout{
    &__sidebar-fix{
        position: absolute;
        top: 0; 
        right:16.5%;
        max-width: 21%;
        @include MediaQuery($xxlg){
            max-width: 27.5%;
            right: 10.5%;
        }
        @include MediaQuery($lg){
            max-width: 35.5%;
            right: 0.5%;
        }
        @include MediaQuery($md){
        position: unset;
        max-width: 100%;
        }
        .new-siderbar .sidebar-content{
            padding: 22px 14px 4px 14px;
        }
    }    
}
.d{
    &p--none{
        @include MediaQuery($lg){
            display:none;
        }
    }
}

.coupon-home{
    @include MediaQuery($xs){
        display:none;
    }
}